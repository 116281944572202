// const fs = require("fs");
import { default as categoryNav } from '@/lib/bigcommerce/data/category-nav.json'
import { default as productsUrl } from '@/lib/bigcommerce/data/products-url.json'
import { default as swatches } from '@/lib/bigcommerce/data/swatches.json'
import { default as bestSellersByCategory } from '@/lib/bigcommerce/data/best-sellers'
import { default as reviewScore } from '@/lib/bigcommerce/data/product-reviews'
import { getAvailableVariants } from '@/lib/helpers/pdp'
import { default as headerMenu } from '@/lib/contentful/data/header-menu.json'
import { default as footerMenu } from '@/lib/contentful/data/footer-menu.json'
import { default as contentModel } from '@/lib/contentful/data/content-models.json'

const htmlEntities = {
  nbsp: ' ',
  cent: '¢',
  pound: '£',
  yen: '¥',
  euro: '€',
  copy: '©',
  reg: '®',
  lt: '<',
  gt: '>',
  quot: '"',
  amp: '&',
  apos: "'",
}

export const addForwardSlash = (url) => {
  try {
    if (validURL(url)) {
      return url
    }

    if (url.charAt(0) != '/') {
      return '/' + url
    }

    return url
  } catch (error) {
    console.log(error)
  }
}

export const validURL = (str) => {
  let url
  try {
    url = new URL(str)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const isEmpty = (item) => {
  return !item || item.length === 0
}

export const formatUrlSlug = (url) => {
  let newUrl = url

  if (isEmpty(url)) {
    return '/'
  }

  //for email redirections
  if (url.toLowerCase().includes('mailto:')) {
    return url
  }

  if (validURL(url)) {
    return url
  }

  if (newUrl.charAt(0) != '/') {
    newUrl = '/' + newUrl
  }

  return newUrl.replace(/[\u200B-\u200D\uFEFF]/g, '')
}

export const trimSlashes = (str) => {
  return str.replace(/^\/|\/$/g, '')
}

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const camelCase = (string) => {
  let str = string
  str = str.toLowerCase()

  if (str.split(' ')) {
    const strArr = str
      .split(' ')
      .map((s) => s)
      .join('_')
    str = strArr
  }

  return str
}

export const validatePassword = (values) => {
  let error = ''

  const charArr = values?.split('')
  const repeatedChar = /^(?:(.)(?!\1\1))+$/
  const passwordCombination =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$()\[\].,])[A-Za-z\d!@#$()\[\].,]{8,20}$/
  const unique = [...new Set(charArr)]

  if (!values) {
    error = '*Required'
  } else if (!repeatedChar.test(values)) {
    error = 'Can only repeat the same character 2 times in a row'
  } else if (!passwordCombination.test(values)) {
    error =
      'Please ensure your password is 8 to 20 characters long with one uppercase letter, one lowercase letter, one number and one special character. Allowed special characters are ! @ # $ ( ) [ ] . ,'
  } else if (unique.length <= 4) {
    error = 'Must include 5 unique characters'
  }

  return error
}

export const validateConfirmPassword = (pass, value) => {
  let error = ''
  if (pass && value) {
    if (pass !== value) {
      error = 'Both passwords do not match'
    }
  }
  return error
}

export const buildSelectedOptions = (options, optionIndex = 0) => {
  if (options) {
    const selectedOptions = options.map((option, index) => {
      let selectedOption = option.option_values.filter((item) => item.is_default)

      return (selectedOption =
        selectedOption.length > 0 ? selectedOption[0] : options[index].option_values[optionIndex])
    })

    return selectedOptions
  }
}

export const getCurrentVariant = ({ selectedOptions, variants }) => {
  return (
    variants?.filter((variant) =>
      variant.option_values.every(
        (option) =>
          selectedOptions[option.option_display_name] &&
          selectedOptions[option.option_display_name].label === option.label
      )
    )?.[0] || selectedOptions
  )
}

export const getCurrentVariantFromSelectedOptions = ({ selectedOptions, variants }) => {
  const availableVariants = getAvailableVariants(variants)
  return (
    availableVariants?.filter((variant) =>
      variant.option_values.every(
        (option) => selectedOptions[option?.option_display_name]?.label === option.label
      )
    )?.[0] || null
  )
}

export const buildCurrentVariant = (variants, variantIndex = 0) => {
  let currentVariant = getAvailableVariants(variants)?.[variantIndex]
  return currentVariant || null
}

export const isProductOptionAvailable = ({ selectedOptions, variants }) => {
  return getCurrentVariantFromSelectedOptions({ selectedOptions, variants }) !== null
}

export const getProductWhyWeMadeThis = (data) => {
  if (!data || !data.value) return null

  try {
    return JSON.parse(data.value).en
  } catch (e) {
    return null
  }
}

export const getVariantFeaturesAndSpecs = ({ variant }) => {
  try {
    const productFeatures = variant.metafields.find((meta) => meta.key === 'product_features')
    const line = JSON.parse(productFeatures.value).en.split('\n').join('<br/>')
    let lines = line?.split('<br/>')

    if (lines && lines.length > 0) {
      lines = lines.map((line) => `<div class='feature_item'>${line}</div>`).join('')
      return lines
    }

    return JSON.parse(productFeatures.value).en.split('\n').join('<br/>')
  } catch (e) {
    return null
  }
}

export const getAllCategoryPaths = () => {
  const categoryPaths = new Array()
  categoryNav.data.map((item) => {
    // if(item.name != "Activity" && item.name != "Technology") {
    categoryPaths.push({
      title: item.name,
      slug: item.url + 'all/',
      id: item.id,
    })
    if (item.children.length > 0) {
      item.children.map((i) => {
        categoryPaths.push({
          title: i.name,
          slug: i.url,
          id: i.id,
        })
        if (i.children.length > 0) {
          i.children.map((it) => {
            categoryPaths.push({
              title: it.name,
              slug: it.url,
              id: it.id,
            })
            if (it.children.length > 0) {
              it.children.map((itm) => {
                categoryPaths.push({
                  title: itm.name,
                  slug: itm.url,
                  id: itm.id,
                })
              })
            }
          })
        }
      })
    }
    // }
  })
  return categoryPaths
}

export const getProductByHandle = (handle) => {
  let product = null

  if (handle) {
    product = productsUrl[`/${handle}/`]
  }

  return product
}

export const getParentCategoryKey = () => {
  const categoryKeys = []
  categoryNav.data.map((category) => {
    categoryKeys.push(category.url.replace(/\//g, ''))
  })
  return categoryKeys
}

export const buildSwatchesUrl = (optionValue) => {
  let url = '/images/photo-empty.png'
  if (optionValue && optionValue.label) {
    try {
      let formatedLabel = optionValue.label.toLowerCase().split(' ').join('_')
      let formatedLabel2 = optionValue.label.toLowerCase().split(' ').join('-')
      let formatedLabel3 = formatedLabel?.split('/')
      let formatedLabel4 = formatedLabel2?.split('/')

      checkIfImageExists(formatedLabel, (exist) => {
        if (exist) {
          url = '/images/swatch/' + exist
        } else {
          checkIfImageExists(formatedLabel2, (exist) => {
            if (exist) {
              url = '/images/swatch/' + exist
            } else {
              formatedLabel3?.length > 0 &&
                checkIfImageExists(formatedLabel3[0], (exist) => {
                  if (exist) {
                    url = '/images/swatch/' + exist
                  } else {
                    formatedLabel4?.length > 0 &&
                      checkIfImageExists(formatedLabel4[0], (exist) => {
                        if (exist) {
                          url = '/images/swatch/' + exist
                        }
                      })
                  }
                })
            }
          })
        }
      })
    } catch (error) {
      console.log('error', error)
    }
  }
  return url
}

export const checkIfImageExists = (url, callback) => {
  const filteresSwatches = swatches.filter(
    (swatch) => swatch.toLowerCase() == url || swatch.toLowerCase().includes(url)
  )

  if (filteresSwatches && filteresSwatches.length > 0) {
    callback(
      filteresSwatches.filter((swatch) => swatch == url)[0] ??
        filteresSwatches.filter((swatch) => swatch.includes(url))[0]
    )
  } else {
    callback(false)
  }
}

export const getUSStates = () => {
  const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ]
  return states
}
export const getCAStates = () => {
  const states = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon Territory',
  ]
  return states
}

export const getCountryCode = (testCountry) => {
  const countries = {
    Albania: 'AL',
    Afghanistan: 'AF',
    Algeria: 'DZ',
    'American Samoa': 'AS',
    Andorra: 'AD',
    Angola: 'AO',
    Anguilla: 'AI',
    'Antigua, Barbuda': 'AG',
    Argentina: 'AR',
    Aruba: 'AW',
    'Australia, Norfolk Island': 'AU',
    Austria: 'AT',
    Azerbaijan: 'AZ',
    Bahamas: 'BS',
    Bahrain: 'BH',
    Bangladesh: 'BD',
    Barbados: 'BB',
    Belarus: 'BY',
    Belgium: 'BE',
    Belize: 'BZ',
    Benin: 'BJ',
    Bermuda: 'BM',
    Bhutan: 'BT',
    Bolivia: 'BO',
    'Bosnia-Herzegovina': 'BA',
    Botswana: 'BW',
    Brazil: 'BR',
    Brunei: 'BN',
    Bulgaria: 'BG',
    'Burkina Faso': 'BF',
    Burundi: 'BI',
    Cambodia: 'KH',
    Cameroon: 'CM',
    Canada: 'CA',
    'Cape Verde': 'CV',
    Chad: 'TD',
    Chile: 'CL',
    'China, People Republic Of': 'CN',
    Colombia: 'CO',
    Congo: 'CG',
    'Congo, Dem Rep Of': 'Congo, Dem Rep Of',
    'Cook Islands': 'CK',
    'Costa Rica': 'CR',
    Croatia: 'HR',
    Curacao: 'CW',
    Cyprus: 'CY',
    'Czech Republic': 'CZ',
    Denmark: 'DK',
    Djibouti: 'DJ',
    Dominica: 'DM',
    'Dominican Republic': 'DO',
    'East Timor': 'TL',
    Ecuador: 'EC',
    Egypt: 'EG',
    'El Salvador': 'SV',
    'England, Great Britain, Northern Ireland, Scotland, United Kingdom, Wales, Channel Islands':
      'GB',
    Eritrea: 'ER',
    Estonia: 'EE',
    Ethiopia: 'ET',
    'Faeroe Islands': 'FO',
    Fiji: 'FJ',
    Finland: 'FI',
    France: 'FR',
    'French Guiana': 'GF',
    Gabon: 'GA',
    Gambia: 'GM',
    'Georgia, Republic Of': '',
    Germany: 'DE',
    Ghana: 'GH',
    'Grand Cayman, Cayman Islands': 'KY',
    Gibraltar: 'GI',
    'Great Thatch Island, Great Tobago Islands, Jost Van Dyke Islands, Norman Island, Tortola Island, British Virgin Islands':
      'VG',
    Greece: 'GR',
    Greenland: 'GL',
    Grenada: 'GD',
    Guadeloupe: 'GP',
    Guam: 'GU',
    Guatemala: 'GT',
    Guinea: 'GN',
    'Guinea Bissau': 'GW',
    Guyana: 'GY',
    Haiti: 'HT',
    Honduras: 'HN',
    'Hong Kong, SAR, China': 'HK',
    Hungary: 'HU',
    Iceland: 'IS',
    India: 'IN',
    Indonesia: 'ID',
    Iraq: 'IQ',
    'Northern Republic Of Israel': 'IL',
    'Ivory Coast': 'CI',
    Jamaica: 'JM',
    Japan: 'JP',
    Jordan: 'JO',
    Kazakhstan: 'KZ',
    Kenya: 'KE',
    'Korea, South (South Korea)': 'KR',
    Kuwait: 'KW',
    Kyrgyzstan: 'KG',
    Laos: 'LA',
    Latvia: 'LV',
    Lebanon: 'LB',
    Lesotho: 'LS',
    Liberia: 'LR',
    Libya: 'LY',
    Liechtenstein: 'LI',
    Lithuania: 'LT',
    Luxembourg: 'LU',
    'Macau SAR, China': 'MO',
    Macedonia: 'MK',
    Madagascar: 'MG',
    Malawi: 'MW',
    Malaysia: 'MY',
    'Maldives, Republic Of': 'MV',
    Mali: 'ML',
    Malta: 'MT',
    'Marshall Islands': 'MH',
    Martinique: 'MQ',
    Mauritania: 'MR',
    Mauritius: 'MU',
    Mexico: 'MX',
    Micronesia: 'FM',
    Moldova: 'MD',
    Monaco: 'MC',
    Mongolia: 'MN',
    Montserrat: 'MS',
    Morocco: 'MA',
    Mozambique: 'MZ',
    Namibia: 'NA',
    Nepal: 'NP',
    'Netherlands (Holland)': 'NL',
    'Netherlands Antilles (Caribbean)': 'AN',
    'New Caledonia': 'NC',
    'New Guinea, Papua New Guinea': 'PG',
    'New Zealand': 'NZ',
    Nicaragua: 'NI',
    Niger: 'NE',
    Nigeria: 'NG',
    Norway: 'NO',
    Oman: 'OM',
    Pakistan: 'PK',
    Palau: 'PW',
    Panama: 'PA',
    Paraguay: 'PY',
    Peru: 'PE',
    Philippines: 'PH',
    Poland: 'PL',
    'Portugal, Azores, Madeira': 'PT',
    'Puerto Rico': 'PR',
    Qatar: 'QA',
    'Reunion Island': 'RE',
    Romania: 'RO',
    'Rota, Tinian, Saipan': 'MP',
    Russia: 'RU',
    Rwanda: 'RW',
    'Bonaire, Caribbean Netherlands, Saba, St. Eustatius': 'BQ',
    'Saudi Arabia': 'SA',
    Senegal: 'SN',
    Serbia: 'RS',
    Seychelles: 'SC',
    Singapore: 'SG',
    'Slovak Republic': 'SK',
    Slovenia: 'SI',
    'South Africa, Republic Of': 'ZA',
    'Spain, Canary Islands': 'ES',
    'Sri Lanka': 'LK',
    'St. Barthelemy': 'BL',
    'Nevis, St. Christopher, St. Kitts And Nevis': 'KN',
    'St. Croix Island, St. John, St. Thomas': 'VI',
    'St. Lucia': 'LC',
    'St. Maarten': 'SX',
    'St. Martin': 'MF',
    'St. Vincent, Union Island': 'VC',
    Suriname: 'SR',
    Swaziland: 'SZ',
    Sweden: 'SE',
    Switzerland: 'CH',
    'Tahiti, French Polynesia': 'PF',
    'Taiwan, China': 'TW',
    Tanzania: 'TZ',
    Thailand: 'TH',
    Togo: 'TG',
    Tonga: 'TO',
    'Trinidad and Tobago': 'TT',
    Tunisia: 'TN',
    Turkey: 'TR',
    Turkmenistan: 'TM',
    'Turks and Caicos Islands': 'TC',
    Tuvalu: 'TV',
    Uganda: 'UG',
    Ukraine: 'UA',
    'United Arab Emirates': 'AE',
    'United States': 'US',
    Uruguay: 'UY',
    Uzbekistan: 'UZ',
    Vanuatu: 'VU',
    'Vatican City, San Marino, Italy': 'IT',
    Venezuela: 'VE',
    Vietnam: 'VN',
    'Wallis and Futuna Islands': 'WF',
    Zambia: 'ZM',
    Zimbabwe: 'ZW',
  }

  return countries[testCountry] ? countries[testCountry] : testCountry
}

export const getStatesCode = (country, testState) => {
  const states = {
    'United States': {
      Alabama: 'AL',
      Alaska: 'AK',
      Arizona: 'AZ',
      Arkansas: 'AR',
      'Armed Forces America': 'AA',
      'Armed Forces Europe': 'AE',
      'Armed Forces Pacific': 'AP',
      California: 'CA',
      Colorado: 'CO',
      Connecticut: 'CT',
      Delaware: 'DE',
      'District of Columbia': 'DC',
      Florida: 'FL',
      Georgia: 'GA',
      Hawaii: 'HI',
      Idaho: 'ID',
      Illinois: 'IL',
      Indiana: 'IN',
      Iowa: 'IA',
      Kansas: 'KS',
      Kentucky: 'KY',
      Louisiana: 'LA',
      Maine: 'ME',
      Maryland: 'MD',
      Massachusetts: 'MA',
      Michigan: 'MI',
      Minnesota: 'MN',
      Mississippi: 'MS',
      Missouri: 'MO',
      Montana: 'MT',
      Nebraska: 'NE',
      Nevada: 'NV',
      'New Hampshire': 'NH',
      'New Jersey': 'NJ',
      'New Mexico': 'NM',
      'New York': 'NY',
      'North Carolina': 'NC',
      'North Dakota': 'ND',
      Ohio: 'OH',
      Oklahoma: 'OK',
      Oregon: 'OR',
      Pennsylvania: 'PA',
      'Rhode Island': 'RI',
      'South Carolina': 'SC',
      'South Dakota': 'SD',
      Tennessee: 'TN',
      Texas: 'TX',
      Utah: 'UT',
      Vermont: 'VT',
      Virginia: 'VA',
      'Washington State': 'WA',
      Washington: 'WA',
      'West Virginia': 'WV',
      Wisconsin: 'WI',
      Wyoming: 'WY',
      'Puerto Rico': 'PR',
    },
    Canada: {
      Alberta: 'AB',
      'British Columbia': 'BC',
      Manitoba: 'MB',
      'New Brunswick': 'NB',
      Newfoundland: 'NL',
      'Newfoundland and Labrador': 'NL',
      'Northwest Territories': 'NT',
      'Nova Scotia': 'NS',
      Nunavut: 'NU',
      Ontario: 'ON',
      'Prince Edward Island': 'PE',
      Quebec: 'QC',
      Saskatchewan: 'SK',
      Yukon: 'YT',
    },
  }

  return states[country][testState] ? states[country][testState] : testState
}

export const getBestSellersByCategory = (categoryId) => {
  return bestSellersByCategory.find((bestSeller) => bestSeller.id === categoryId)?.bestSellers || []
}

export const getBestSellersByCategoryTitle = (title) => {
  const lowerTitle = title.toLowerCase()
  return (
    bestSellersByCategory.reverse().find((bestSeller) => {
      const lowerBestSellerTitle = bestSeller?.title?.toLowerCase()
      return lowerBestSellerTitle.includes(lowerTitle) || lowerTitle?.includes(lowerBestSellerTitle)
    })?.bestSellers || []
  )
}

// const findItemNested = (arr, itemId, nestingKey) =>
//   arr.reduce((a, item) => {
//     if (a) return a
//     if (item.id === itemId) return item
//     if (item[nestingKey]) return findItemNested(item[nestingKey], itemId, nestingKey)
//   }, null)

//   }
//   return unique.join(' / ')
// }

export const stringContainsOnlySpaces = (str) => {
  return str.trim().length === 0
}

export const formatProductPrice = (price) => {
  if (price) {
    // check if price has decimal points
    if (Number.isInteger(price)) {
      return '$' + price?.toFixed(2)
    } else {
      return '$' + parseFloat(price)?.toFixed(2)
    }
  } else {
    return '$ -'
  }
}

export const formatDiscountPrice = (price) => {
  const dicsountPrice = formatProductPrice(price)
  return price > 0 ? `-${dicsountPrice}` : dicsountPrice
}

export const unescapeHTML = (str) => {
  console.log(str)
  return str.replace(/\&([^;]+);/g, function (entity, entityCode) {
    var match

    if (entityCode in htmlEntities) {
      return htmlEntities[entityCode]
      /*eslint no-cond-assign: 0*/
    } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
      return String.fromCharCode(parseInt(match[1], 16))
      /*eslint no-cond-assign: 0*/
    } else if ((match = entityCode.match(/^#(\d+)$/))) {
      return String.fromCharCode(~~match[1])
    } else {
      return entity
    }
  })
}

export const formatSlugArray = (slug) => {
  if (typeof slug == 'string') {
    return slug
  }

  if (typeof slug == 'object' && Array.isArray(slug)) {
    let newSlug = slug[0]

    if (slug.length > 1) {
      newSlug = slug.join('/')
    }

    return newSlug
  }
}
export const formatPhoneNumber = (phoneNumber, profilePhone) => {
  let defaultPhone = phoneNumber?.replace(/[^\d]/g, '')
  let profilePhoneNumber = profilePhone?.replace(/[^\d]/g, '')
  if (defaultPhone && defaultPhone?.length == 10) {
    //reformat and return phone number
    return defaultPhone?.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3')
  } else if (profilePhoneNumber?.length == 10) {
    //reformat and return phone number
    return profilePhoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3')
  } else {
    return
  }
}

export const defaultAddress = (address) => {
  const default_address =
    address?.length > 0 &&
    address?.find((item) =>
      item?.form_fields?.find((elem) => elem.name === 'default_address' && elem.value === 'true')
    )
  return default_address
}

export const getProductReviewCount = (sku) => {
  return reviewScore.find((item) => String(item.id) == sku)
}

export const sendPixelData = () => {
  let pixelFrame = document.getElementById('iframe-pixel')

  if (pixelFrame) {
    pixelFrame.contentWindow.postMessage(
      {
        action: 'localVars',
        cartItems: window.localStorage.getItem('cartItems'),
        utmParams: window.localStorage.getItem('utmParams'),
      },
      '*'
    )
  }
}

export const getHeaderMenu = () => {
  return headerMenu
}

export const getFooterMenu = () => {
  return footerMenu
}

export const removeFirstSlug = (string) => {
  if (string.charAt(0) === '/') {
    let removeSlug = string.substring(1) // Removes the leading '/' only if it's present
    return removeSlug
  } else {
    return string
  }
}

export const isDifferentSource = (slug) => {
  if (slug?.startsWith('/')) {
    return `${process.env.NEXT_PUBLIC_BASE_URL + '/' + removeFirstSlug(slug)}`
  } else {
    return slug
  }
}

export const getColorName = (string) => {
  const colorName = string && string?.toLowerCase()?.replaceAll(' ', '-')?.replaceAll('/', '-')
  return colorName
}

export const findSelectedVariantSku = (color, size, array) => {
  const foundColorItem =
    array?.length &&
    array?.filter((item) =>
      item?.option_values?.some(
        (option) => option?.label === color && option?.option_display_name === 'Color'
      )
    )
  const foundSizeItem =
    foundColorItem?.length &&
    foundColorItem?.find((item) =>
      item?.option_values?.some(
        (option) => option?.label === size && option?.option_display_name === 'Size'
      )
    )
  return foundSizeItem ? foundSizeItem?.sku : null
}

export const isDateValid = (dateString) => {
  const dateRegex = /^(?:\d{4}-\d{2}-\d{2}(?: \d{2}:\d{2})?)$/

  if (!dateRegex.test(dateString)) {
    return false
  }

  const dateObj = new Date(dateString)

  if (isNaN(dateObj?.getTime())) {
    return false
  }

  return dateString
}

export const displayWithCurrentDate = (startDate, endDate) => {
  const currentTime = new Date()

  if (startDate && endDate) {
    let startObj = new Date(startDate)
    let endObj = new Date(endDate)

    return currentTime >= startObj && currentTime < endObj
  }

  if (startDate && !endDate) {
    let startObj = new Date(startDate)

    return currentTime >= startObj
  }

  if (endDate && !startDate) {
    let endObj = new Date(endDate)

    return currentTime < endObj
  }

  // if no time related custom fields
  return true
}

export const normalizeUrlPath = (url) => {
  const urlObject = new URL(url, process.env.NEXT_PUBLIC_BASE_URL)

  const normalizedPath = urlObject.pathname.replace(/\/+/g, '/')

  return normalizedPath
}

export const getNavAdditionalContentType = (nav) => {
  try {
    if (nav?.marketingContent?.__typename) {
      let marketingType = nav?.marketingContent?.__typename

      if (marketingType == 'ModuleHero') {
        return 'Image Text CTA'
      }

      if (marketingType == 'ModuleMarkdownText') {
        return 'HTML'
      }
    }

    return 'Product Grid'
  } catch (error) {
    console.log('getNavAdditionalContentType error')
    return 'Product Grid'
  }
}

export const findParentWithClass = (eventTarget, className, maxIterations) => {
  let currentElement = eventTarget
  let iterations = 0

  while (currentElement != null && iterations < maxIterations) {
    if (currentElement.classList && currentElement.classList.contains(className)) {
      return true
    }

    currentElement = currentElement.parentNode
    iterations++
  }

  return false
}

export const getContentModel = (contentType) => {
  let model = contentModel.find((content) => content.id == contentType)
  return model
}

export const hasTimePassed = (timestamp, milliseconds) => {
  if (typeof timestamp !== 'number' || isNaN(timestamp)) {
    return true
  }

  if (typeof milliseconds !== 'number' || isNaN(milliseconds)) {
    return true
  }

  const currentTime = new Date().getTime()

  const targetTime = timestamp + milliseconds

  return currentTime >= targetTime
}

export const retry = async (fn, retries = 3, delay = 1000) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await fn()
    } catch (error) {
      if (i === retries - 1) throw error
      await new Promise((resolve) => setTimeout(resolve, delay))
    }
  }
}
/**
 * Returns parsed JSON string if successful,
 * Otherwise returns fallback value
 * @param data - JSON string
 * @param fallbackValue - fallback value
 */
export const jsonParse = (data, fallbackValue = null) => {
  try {
    return data ? JSON.parse(data) : fallbackValue;
  } catch (error) {
    return fallbackValue;
  }
};

export const hiddenWebHeader = (isHidden) => {
  if (typeof window != 'undefined') {
    const webHeader = document.querySelector('.web-header')
    isHidden ? webHeader?.classList?.add('hidden') : webHeader?.classList?.remove('hidden')
  }
}

