import Cookies from 'js-cookie'
import * as types from '@/redux/actionTypes'

import { updateCartModal } from './uiAction'
import { getProductInventory } from './productAction'
import { addToCart, getCartData, removeCartItem, updateCart } from '@/lib/api/cart'
import { getProductByIdForCustomField, getQuantityOfCartProduct } from '@/lib/api/products'
import { addToCartEvent } from '@/lib/ga-events'

// Tracking
import { trackEvent } from '@/lib/tracking'

export const calculatedTotalDiscount = (items) => {
  return items?.length > 0 ? items?.reduce((total, item) => total + item.discount_amount, 0) : 0
}

export const addToCartItem = async (lineItem, dispatch, customerId, errorCallback) => {
  dispatch({ type: types.ADD_CART_REQUEST })
  try {
    // for multiple product selection in system builder I need to pass lineItem as array format so added below params conditionally,
    // but it won't break your old add to cart functionality for single product. so please keep this.

    const { data, errorData } = await addToCart(
      {
        line_items: Array.isArray(lineItem) ? lineItem : lineItem?.quantity ? [lineItem] : [],
        gift_certificates: lineItem.gift_certificates ? lineItem?.gift_certificates : [],
      },
      customerId
    )

    if (errorCallback && errorData) {
      errorCallback(errorData)
      return
    }

    data &&
      dispatch({
        type: types.ADD_CART_SUCCESS,
        payload: {
          ...data,
          discount_amount_total: calculatedTotalDiscount(data.line_items.physical_items),
          all_cart_items: data?.line_items?.physical_items?.map((item) => item) || [], //({ ...item, added_to_wishlist: false}))
          all_gift_items: data?.line_items?.gift_certificates?.map((item) => item) || [],
        },
      })
    data && !lineItem.gift_certificates && addToCartEvent(lineItem, data)
    await dispatch(updateCartModal(true))
    await getCart(dispatch)
    if (Array.isArray(lineItem)) {
      await Promise.all(
        lineItem.map((product) => {
          return trackEvent({
            event: 'Added to Cart',
            lineItem: product,
            customerId: customerId,
          })
        })
      )
    } else {
      await trackEvent({
        event: 'Added to Cart',
        lineItem,
        customerId: customerId,
      })
    }
    return data
  } catch (err) {
    dispatch({ type: types.ADD_CART_FAILURE })
    Cookies.remove('cart')
    addToCartItem(lineItem, dispatch, customerId)
    console.error('addToCart err', err)
    throw err
  }
}

export const addToCartItems = async (lineItems, dispatch, customerId) => {
  dispatch({ type: types.ADD_CART_REQUEST })

  try {
    const { data } = await addToCart({ line_items: lineItems }, customerId)

    data &&
      dispatch({
        type: types.ADD_CART_SUCCESS,
        payload: {
          ...data,
          discount_amount_total: calculatedTotalDiscount(data.line_items.physical_items),
          all_cart_items: data.line_items.physical_items.map((item) => item) || [],
          all_gift_items: data?.line_items?.gift_certificates?.map((item) => item) || [],
        },
      })

    data & addToCartEvent(lineItems, data)

    await dispatch(updateCartModal(true))
    await getCart(dispatch)
    return data
  } catch (err) {
    dispatch({ type: types.ADD_CART_FAILURE })
    console.error('addToCart err', err)
    throw err
  }
}

export const addStdProductToCart = async (
  customer,
  modifiers,
  product_id,
  quantity,
  variant_id,
  originalPrice,
  salePrice,
  shippingNotAllowed,
  dispatch,
  variant = null
) => {
  const addModifier = await addModifiers(
    product_id,
    variant_id,
    quantity,
    originalPrice,
    salePrice,
    modifiers,
    shippingNotAllowed,
    false,
    variant
  )
  const data = await addToCartItem(
    addModifier,
    dispatch,
    (customer && customer?.profile && customer?.profile?.id) || null
  )
  return data
}

export const addProProductToCart = async (
  customer,
  modifiers,
  product_id,
  quantity,
  variant_id,
  originalPrice,
  salePRice,
  dispatch,
  variant,
  shippingNotAllowed
) => {
  const addModifier = await addModifiers(
    product_id,
    variant_id,
    quantity,
    originalPrice,
    salePRice,
    modifiers,
    shippingNotAllowed,
    true,
    variant
  )
  addToCartItem(
    addModifier,
    dispatch,
    (customer && customer?.profile && customer?.profile?.id) || null
  )
}

export const checkProInventoryOfProduct = async (
  variant,
  all_cart_items,
  quantity,
  customer,
  modifiers,
  originalPrice,
  salePrice,
  shippingNotAllowed,
  dispatch
) => {
  const cachedInventory = await dispatch(getProductInventory())

  const productInventory = await getQuantityOfCartProduct(
    variant?.sku,
    variant?.product_id == cachedInventory?.productId ? cachedInventory : null
  )

  const cartProductQuantity = all_cart_items.find(
    (item) => item?.sku === variant?.sku && item?.options?.find((el) => el.value === '03')
  )?.quantity

  if (productInventory <= cartProductQuantity || productInventory <= 0) {
    if (variant) {
      const modifierAdd = await addModifiers(
        variant?.product_id,
        variant?.id,
        quantity,
        variant?.price || variant?.calculated_price,
        variant?.sale_price,
        modifiers,
        shippingNotAllowed,
        false,
        variant
      )

      addToCartItem(
        modifierAdd,
        dispatch,
        (customer && customer?.profile && customer?.profile?.id) || null
      )
    }
  } else {
    await addProProductToCart(
      customer,
      modifiers,
      variant?.product_id,
      quantity,
      variant?.id,
      originalPrice,
      variant?.sale_price,
      dispatch,
      variant,
      shippingNotAllowed
    )
  }
}

export const addModifiers = async (
  productId,
  variantId,
  quantity,
  originalPrice,
  salePrice,
  modifiers,
  shippingNotAllowed,
  isProUser,
  variant
) => {
  //fetch product custom option for product_type
  const custom_opt = await getProductByIdForCustomField(productId)
  const productType =
    custom_opt && custom_opt?.custom_fields?.find((item) => item.name == 'product_type')
  const productExclude =
    custom_opt && custom_opt?.custom_fields?.find((item) => item.name == 'exclude_pro_discount')
  let isProductWader = false
  if (productType || productExclude) {
    if (
      productType?.value?.toLowerCase() == 'wader' ||
      productExclude?.value?.toLowerCase() === 'true'
    )
      isProductWader = true
  }

  //check if the product have sale price or not
  let checkIsSale
  let listPrice
  if (isProUser) {
    checkIsSale =
      variant?.sale_price && variant?.sale_price !== 0
        ? +variant?.sale_price < +variant?.price
        : +variant?.price > +originalPrice
    listPrice =
      +variant?.calculated_price > +originalPrice ? originalPrice : variant?.calculated_price
  } else {
    checkIsSale = salePrice && +salePrice !== 0 ? +salePrice < +originalPrice : false
    listPrice = checkIsSale ? +salePrice : +originalPrice
  }

  // if product type is Wader, then set sale modifier
  // if (isProductWader) checkIsSale = true
  // for sale modifiers
  let option_value_sale = null
  let option_id_sale = null

  // modifiers for Sale
  modifiers?.find((obj) => {
    if (obj.display_name?.toLowerCase() === 'sale') {
      if (checkIsSale) {
        obj?.option_values?.find((opt) => {
          if (opt?.label === 'Yes') {
            option_id_sale = opt?.option_id
            option_value_sale = opt?.id
          }
        })
      } else {
        obj?.option_values?.find((opt) => {
          if (opt?.label === 'No') {
            option_id_sale = opt?.option_id
            option_value_sale = opt?.id
          }
        })
      }
    }
  })

  // modifiers for cart_data
  let cartOptId = null
  let cartOptValue = null
  modifiers?.find((obj) => {
    if (obj.display_name?.toLowerCase() === 'cart data') {
      cartOptId = obj.id
      cartOptValue = shippingNotAllowed
    }
  })

  // for warehouse modifiers
  let option_value = null
  let option_id = null

  // modifiers for Warehouse
  modifiers?.find((obj) => {
    if (obj.display_name?.toLowerCase() === 'warehouse') {
      obj?.option_values?.map((opt) => {
        if (isProUser && opt?.label === '03') {
          option_id = opt?.option_id
          option_value = opt?.id
          return
        } else if (opt?.label === '02') {
          option_id = opt?.option_id
          option_value = opt?.id
          return
        }
      })
    }
  })

  let colorCodeValue = variant?.sku ? variant?.sku?.split('-')?.[1] : null
  let colorCodeId = null

  if (colorCodeValue) {
    modifiers?.find((obj) => {
      if (obj.display_name?.toLowerCase() === 'color code') {
        colorCodeId = obj?.id
      }
    })
  }

  let optionSection = []

  if (option_id) {
    optionSection = [
      ...optionSection,
      {
        option_id: option_id,
        option_value: option_value,
      },
    ]
  }

  if (option_id_sale) {
    optionSection = [
      ...optionSection,
      {
        option_id: option_id_sale,
        option_value: option_value_sale,
      },
    ]
  }

  if (cartOptId) {
    optionSection = [
      ...optionSection,
      {
        option_id: cartOptId,
        option_value: cartOptValue,
      },
    ]
  }
  if (colorCodeId) {
    optionSection = [
      ...optionSection,
      {
        option_id: colorCodeId,
        option_value: colorCodeValue,
      },
    ]
  }

  let payload = {
    product_id: productId,
    quantity: quantity,
    variant_id: variantId,
    list_price: +listPrice,
    option_selections: optionSection,
  }
  return payload
}

export const getCart = async (dispatch) => {
  dispatch({ type: types.LOAD_CART_REQUEST })

  try {
    const { data } = await getCartData()

    if (data && data.line_items) {
      dispatch({
        type: types.ADD_CART_SUCCESS,
        payload: {
          ...data,
          discount_amount_total: calculatedTotalDiscount(data.line_items.physical_items),
          all_cart_items: data.line_items.physical_items.map((item) => item), //({ ...item, added_to_wishlist: false}))
          all_gift_items: data?.line_items?.gift_certificates?.map((item) => item) || [],
        },
      })
    }
    return data
  } catch (error) {
    dispatch({ type: types.ADD_CART_FAILURE })
    console.error('get cart error', error)
    throw error
  }
}

export const deleteCartItem = async (itemId, dispatch) => {
  dispatch({ type: types.DELETE_CART_REQUEST })

  try {
    const { data } = await removeCartItem(String(itemId))
    console.log(data)

    if (data && data.id) {
      dispatch({
        type: types.DELETE_CART_SUCCESS,
        payload: {
          ...data,
          discount_amount_total: calculatedTotalDiscount(data.line_items.physical_items),
          all_cart_items: data.line_items.physical_items.map((item) => item),
          all_gift_items: data?.line_items?.gift_certificates?.map((item) => item) || [],
        },
      })
    } else {
      dispatch({
        type: types.DELETE_CART_SUCCESS,
        payload: {
          base_amount: 0,
          cart_amount: 0,
          channel_id: 0,
          total_item: 0,
          coupons: [],
          created_time: '',
          currency: {},
          customer_id: 0,
          discount_amount: 0,
          email: '',
          id: '',
          line_items: {
            custom_items: [],
            digital_items: [],
            gift_certificates: [],
            physical_items: [],
          },
          locale: 'en',
          tax_included: false,
          updated_time: '',
          discount_amount_total: 0,
          all_cart_items: [],
          all_gift_items: [],
        },
      })
    }

    return data
  } catch (error) {
    dispatch({ type: types.DELETE_CART_FAILURE })
    console.error('deleteCartItem error', error)
    throw error
  }
}

export const updateCartItem = async (product, quantity, dispatch, cart, customerId, signal) => {
  dispatch({ type: types.UPDATE_CART_REQUEST })
  try {
    const response = await updateCart(product, quantity, customerId, signal)
    if (response.data && response.data.id) {
      dispatch({
        type: types.UPDATE_CART_SUCCESS,
        payload: {
          ...response.data,
          discount_amount_total: calculatedTotalDiscount(response.data.line_items.physical_items),
          all_cart_items: response.data.line_items.physical_items.map((item) => item),
          all_gift_items: response.data?.line_items?.gift_certificates?.map((item) => item) || [],
        },
      })
    } else {
      cart &&
        dispatch({
          type: types.UPDATE_CART_SUCCESS,
          payload: {
            ...cart,
          },
        })
    }

    return response
  } catch (error) {
    dispatch({ type: types.UPDATE_CART_FAILURE })
    throw error
  }
}

export const updateCartItemOptions = async (cart, variantId, options, dispatch) => {
  getCart(dispatch)
}

export const clearCartItem = () => async (dispatch, getState) => {
  dispatch({ type: types.CLEAR_CART_REQUEST })

  try {
    return await dispatch({ type: types.CLEAR_CART_SUCCESS })
  } catch (err) {
    return dispatch({ type: types.CLEAR_CART_FAILURE })
  }
}